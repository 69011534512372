import React, { useRef, useState } from 'react';
import { useCommonStyles } from '../commonStyles';
import {
  BorderColors,
  Colors,
  FontSizes,
  Select,
  SelectOption,
  Spacing,
  TextColors,
} from '@ateams/components';
import { createUseStyles } from 'react-jss';
import cn from 'classnames';
import { components } from 'react-select';
import useClickOutside from '@src/hooks/useClickOutside';

const useStyles = createUseStyles({
  select: {
    margin: 0,
  },
  tagList: {
    marginTop: Spacing.small,
    '& > div': {
      borderRadius: 4,
      fontSize: FontSizes.xsmall,
      fontWeight: 400,
      color: Colors.dark,
    },
  },
  badge: {
    width: 24,
    height: 24,
    borderRadius: '50%',
    background: Colors.backgroundDark,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: FontSizes.xsmall,
    fontWeight: 400,
    color: Colors.dark,
  },
});

export interface FieldDropdownProps {
  label?: string;
  options: SelectOption[];
  value: SelectOption[] | SelectOption | undefined;
  onChange?: (value: SelectOption | null) => void;
  onRemoveOption?: (option: SelectOption) => void;
  placeholder?: string;
  className?: string;
  labelClassName?: string;
  isMulti?: boolean;
  error?: string;
  name?: string;
  clearErrors?: (name?: string) => void;
  disabled?: boolean;
  isClearable?: boolean;
}

export const FieldDropdown = ({
  label,
  options,
  value,
  onChange,
  onRemoveOption,
  placeholder,
  className,
  labelClassName,
  isMulti,
  error,
  name,
  clearErrors,
  disabled,
  isClearable,
}: FieldDropdownProps) => {
  const commonStyles = useCommonStyles();
  const styles = useStyles();
  const selectRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  useClickOutside(selectRef, () => {
    setIsOpen(false);
  });

  const handleChange = (value: SelectOption | null) => {
    onChange?.(value);
    clearErrors?.(name);
  };

  return (
    <div
      className={cn(commonStyles.field, className)}
      ref={selectRef}
      data-name={name}
    >
      {label && (
        <div className={cn(commonStyles.label, labelClassName)}>{label}</div>
      )}
      <Select
        options={options}
        value={value}
        onChange={(value) => handleChange(value as SelectOption | null)}
        onRemoveOption={(option) => onRemoveOption?.(option as SelectOption)}
        placeholder={!disabled ? placeholder : ''}
        className={styles.select}
        menuIsOpen={isOpen}
        onMenuOpen={() => setIsOpen(true)}
        onMenuClose={() => setIsOpen(false)}
        overrideStyles={{
          control: (base) => ({
            ...base,
            height: '32px !important',
            borderColor: BorderColors.light,
            boxShadow: 'none',
            backgroundColor: disabled
              ? Colors.backgroundLight
              : Colors.backgroundWhite,
            cursor: disabled ? 'not-allowed' : 'pointer',
            '&:hover': {
              borderColor: disabled ? BorderColors.lighter : BorderColors.light,
            },
            display: isMulti && disabled ? 'none' : 'flex',
          }),
          input: (base) => ({
            ...base,
            flexGrow: 1,
            padding: 0,
            margin: 0,
          }),
          valueContainer: (base) => ({
            ...base,
            fontSize: 12,
            padding: 0,
            paddingLeft: 10,
          }),
          singleValue: (base) => ({
            ...base,
            color: TextColors.regular,
          }),
          dropdownIndicator: (base) => ({
            ...base,
            display: disabled ? 'none' : 'block',
          }),
        }}
        components={{
          ValueContainer: (props) => {
            const selectedOptionsCount = props.getValue().length;

            return (
              <components.ValueContainer {...props}>
                {props.children}
                {isMulti && selectedOptionsCount > 0 && !disabled && (
                  <span className={styles.badge}>{selectedOptionsCount}</span>
                )}
              </components.ValueContainer>
            );
          },
        }}
        isMulti={isMulti}
        isSearchable
        tagListClassName={styles.tagList}
        disabled={disabled}
        isClearable={isClearable}
      />
      {error && <div className={commonStyles.error}>{error}</div>}
    </div>
  );
};
