import React, { useCallback } from 'react';
import { useCommonStyles } from '../commonStyles';
import { createUseStyles } from 'react-jss';
import {
  BorderColors,
  Colors,
  BorderRadius,
  Spacing,
  FontSizes,
  FontWeights,
  Icon,
  IconType,
} from '@ateams/components';
import { RoleExpertiseScoreItem } from './RoleExpertiseScoreItem';
import TooltipWrapped from '@src/components/TooltipWrapped';
import { FieldCheckbox } from '../general/FieldCheckbox';
import {
  VettingProcessFeedbackRoleExpertiseScore,
  VettingProcessFeedbackRoleExpertiseScoreType,
} from '@ateams/api/dist/endpoints/vetting-process-feedback-v2';

const useStyles = createUseStyles({
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: Colors.backgroundLight,
    borderRadius: BorderRadius.xLarge,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: Spacing.medium,
    border: `1px solid ${BorderColors.light}`,
    borderRadius: BorderRadius.xLarge,
    backgroundColor: Colors.backgroundWhite,
    padding: `${Spacing.xLarge}px ${Spacing.large}px`,
  },
  headersContainer: {
    display: 'grid',
    gridTemplateColumns: '200px 148px 1fr',
    gap: Spacing.medium,
  },
  headerCell: {
    fontSize: FontSizes.medium,
    fontWeight: FontWeights.semiBold,
  },
  rolesContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  divider: {
    borderTop: `1px solid ${BorderColors.light}`,
    margin: 0,
    marginBottom: Spacing.large,
  },
  appliedHeader: {
    fontSize: FontSizes.medium,
    fontWeight: FontWeights.semiBold,
  },
  additionalHeader: {
    fontSize: FontSizes.medium,
    fontWeight: FontWeights.semiBold,
  },
  appliedHeaderContainer: {
    display: 'flex',
    gap: Spacing.xsmall,
    marginBottom: Spacing.small,
  },
  additionalHeaderContainer: {
    display: 'flex',
    gap: Spacing.xsmall,
    marginBottom: Spacing.small,
  },
  infoIcon: {
    cursor: 'pointer',
  },
  tooltipText: {
    fontSize: FontSizes.small,
    maxWidth: '230px',
    padding: 0,
    margin: 0,
  },
  otherContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: Spacing.large,
  },
  otherHeader: {
    fontSize: FontSizes.medium,
    fontWeight: FontWeights.semiBold,
    paddingBottom: Spacing.small,
  },
  otherFieldsContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: Spacing.small,
  },
  checkbox: {
    padding: Spacing.small,
    borderRadius: BorderRadius.small,
    border: `1px solid ${BorderColors.light}`,
    backgroundColor: Colors.backgroundWhite,
  },
});

export interface VettingProcessFeedbackRoleExpertiseScoresData {
  roleExpertiseScores: VettingProcessFeedbackRoleExpertiseScore[];
  individualContributor: boolean;
  teamLead: boolean;
  consultantStrategist: boolean;
  cSuiteLevel: boolean;
}

export interface RoleExpertiseScoresSectionProps {
  roleExpertiseScoresData: VettingProcessFeedbackRoleExpertiseScoresData;
  onRoleExpertiseScoresDataChange: (
    roleExpertiseScoresData: VettingProcessFeedbackRoleExpertiseScoresData,
  ) => void;
}

export const RoleExpertiseScoresSection = ({
  roleExpertiseScoresData,
  onRoleExpertiseScoresDataChange,
}: RoleExpertiseScoresSectionProps) => {
  const commonStyles = useCommonStyles();
  const styles = useStyles();

  const primaryRoleExpertiseScore =
    roleExpertiseScoresData.roleExpertiseScores.find(
      (roleExpertiseScore) =>
        roleExpertiseScore.roleType ===
        VettingProcessFeedbackRoleExpertiseScoreType.PRIMARY,
    );

  const appliedRoleExpertiseScores: VettingProcessFeedbackRoleExpertiseScore[] =
    roleExpertiseScoresData.roleExpertiseScores.filter(
      (roleExpertiseScore) =>
        roleExpertiseScore.roleType ===
        VettingProcessFeedbackRoleExpertiseScoreType.APPLIED,
    );

  const additionalRoleExpertiseScores: VettingProcessFeedbackRoleExpertiseScore[] =
    roleExpertiseScoresData.roleExpertiseScores.filter(
      (roleExpertiseScore) =>
        roleExpertiseScore.roleType ===
        VettingProcessFeedbackRoleExpertiseScoreType.ADDITIONAL,
    );

  const handleRoleExpertiseScoreChange = useCallback(
    (
      roleId: string,
      roleExpertiseScore: VettingProcessFeedbackRoleExpertiseScore,
    ) => {
      onRoleExpertiseScoresDataChange({
        ...roleExpertiseScoresData,
        roleExpertiseScores: roleExpertiseScoresData.roleExpertiseScores.map(
          (existingRoleExpertiseScore) =>
            existingRoleExpertiseScore.roleId === roleId
              ? roleExpertiseScore
              : existingRoleExpertiseScore,
        ),
      });
    },
    [onRoleExpertiseScoresDataChange, roleExpertiseScoresData],
  );

  return (
    <div className={commonStyles.section}>
      <div className={commonStyles.sectionTitle}>Role specialisation</div>
      <div className={styles.mainContainer}>
        <div className={styles.container}>
          <div className={styles.headersContainer}>
            <div className={styles.headerCell}>Primary evaluation role *</div>
            <div className={styles.headerCell}>Level</div>
            <div className={styles.headerCell}>
              Specify the reasons for the role rating *
            </div>
          </div>
          <div className={styles.rolesContainer}>
            {primaryRoleExpertiseScore && (
              <RoleExpertiseScoreItem
                key={primaryRoleExpertiseScore.roleId}
                roleExpertiseScore={primaryRoleExpertiseScore}
                onRoleExpertiseScoreChange={(score) =>
                  handleRoleExpertiseScoreChange(
                    primaryRoleExpertiseScore.roleId,
                    score,
                  )
                }
              />
            )}
            {appliedRoleExpertiseScores.length > 0 && (
              <>
                <hr className={styles.divider} />
                <div className={styles.appliedHeaderContainer}>
                  <div className={styles.appliedHeader}>Applied roles</div>
                  <TooltipWrapped
                    html={
                      <div className={styles.tooltipText}>
                        Roles associated with the mission roles user applied to
                      </div>
                    }
                  >
                    <Icon type={IconType.Info} className={styles.infoIcon} />
                  </TooltipWrapped>
                </div>
                {appliedRoleExpertiseScores.map((appliedRoleExpertiseScore) => (
                  <RoleExpertiseScoreItem
                    key={appliedRoleExpertiseScore.roleId}
                    roleExpertiseScore={appliedRoleExpertiseScore}
                    onRoleExpertiseScoreChange={(score) =>
                      handleRoleExpertiseScoreChange(
                        appliedRoleExpertiseScore.roleId,
                        score,
                      )
                    }
                  />
                ))}
              </>
            )}
            {additionalRoleExpertiseScores.length > 0 && (
              <>
                <hr className={styles.divider} />
                <div className={styles.additionalHeaderContainer}>
                  <div className={styles.additionalHeader}>
                    Additional roles
                  </div>
                  <TooltipWrapped
                    html={
                      <div className={styles.tooltipText}>
                        Other roles the user has on their profile which are
                        adjacent to the main role and within the same category
                      </div>
                    }
                  >
                    <Icon type={IconType.Info} className={styles.infoIcon} />
                  </TooltipWrapped>
                </div>
                {additionalRoleExpertiseScores.map(
                  (additionalRoleExpertiseScore) => (
                    <RoleExpertiseScoreItem
                      key={additionalRoleExpertiseScore.roleId}
                      roleExpertiseScore={additionalRoleExpertiseScore}
                      onRoleExpertiseScoreChange={(score) =>
                        handleRoleExpertiseScoreChange(
                          additionalRoleExpertiseScore.roleId,
                          score,
                        )
                      }
                    />
                  ),
                )}
              </>
            )}
          </div>
        </div>
        <div className={styles.otherContainer}>
          <div className={styles.otherHeader}>Other</div>
          <div className={styles.otherFieldsContainer}>
            <FieldCheckbox
              text={'Individual contributor'}
              value={roleExpertiseScoresData.individualContributor}
              onChange={(value) =>
                onRoleExpertiseScoresDataChange({
                  ...roleExpertiseScoresData,
                  individualContributor: value,
                })
              }
              className={styles.checkbox}
            />
            <FieldCheckbox
              text={'Team lead'}
              value={roleExpertiseScoresData.teamLead}
              onChange={(value) =>
                onRoleExpertiseScoresDataChange({
                  ...roleExpertiseScoresData,
                  teamLead: value,
                })
              }
              className={styles.checkbox}
            />
            <FieldCheckbox
              text={'Consultant strategist'}
              value={roleExpertiseScoresData.consultantStrategist}
              onChange={(value) =>
                onRoleExpertiseScoresDataChange({
                  ...roleExpertiseScoresData,
                  consultantStrategist: value,
                })
              }
              className={styles.checkbox}
            />
            <FieldCheckbox
              text={'C-Suite level'}
              value={roleExpertiseScoresData.cSuiteLevel}
              onChange={(value) =>
                onRoleExpertiseScoresDataChange({
                  ...roleExpertiseScoresData,
                  cSuiteLevel: value,
                })
              }
              className={styles.checkbox}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
